import React from "react";
import "../styles/Header.css";
import logo from "../assets/VogaLogo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosSearch } from "react-icons/io";

const Header = () => {
  return (
    <div className="header">
      <div className="leftMenu">
        <ul>
          <li className="menuItem">Home</li>
          <li className="menuItem">New Arrivals</li>
          <li className="menuItem">Best Sellers</li>
          <li className="menuItem">Combos</li>
          <li className="menuItem">Collections</li>
        </ul>
      </div>

      <div className="hamburger">
        <RxHamburgerMenu />
      </div>

      <div className="sidebarOption">
        <ul>
          <li className="sidebarItem">Home</li>
          <li className="sidebarItem">New Arrivals</li>
          <li className="sidebarItem">Best Sellers</li>
          <li className="sidebarItem">Combos</li>
          <li className="sidebarItem">Collections</li>
        </ul>
      </div>

      <div className="logo">
        <img src={logo} alt="VOGA Logo" />
      </div>

      <div className="searchbar">
        <IoIosSearch />
      </div>

      <div className="rightButtons">
        <input className="searchBtn" type="text" placeholder="Search here..." />
        <button className="loginBtn">Login</button>
      </div>
    </div>
  );
};

export default Header;
